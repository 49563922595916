<template>
    <section id="contacto" class="contact-section">
      <h2>Contacto</h2>
      <p>
        ¿Quieres colaborar o tienes alguna consulta? Puedes contactarme a través de mi correo:
        <a href="mailto:francisco@franciscocarle.com">francisco@franciscocarle.com</a>
      </p>
    </section>
  </template>
  
  <style scoped>
  .contact-section {
    padding: 50px;
    background-color: #f5f5f5;
    text-align: center;
  }
  .contact-section a {
    color: #007acc;
    text-decoration: none;
  }
  </style>
  