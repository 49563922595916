<template>
    <section id="habilidades" class="skills-section">
      <h2>Habilidades</h2>
      <ul>
        <li>Desarrollo web: HTML, CSS, JavaScript, Vue.js, Django</li>
        <li>Gestión ágil: Scrum Master certificado, liderazgo de equipos</li>
        <li>Idiomas: Español (nativo), Inglés (certificación TOEIC)</li>
      </ul>
    </section>
  </template>
  
  <style scoped>
  .skills-section {
    padding: 50px;
    background-color: #f0f8ff;
  }
  .skills-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .skills-section ul {
    list-style: none;
    padding: 0;
  }
  .skills-section li {
    margin: 10px 0;
  }
  </style>
  