<template>
    <section id="sobre-mi" class="about-section">
      <h2>Sobre Mí</h2>
      <p>
        Soy un profesional con experiencia en el desarrollo de software, metodologías ágiles y liderazgo de equipos.
        Me apasiona crear soluciones tecnológicas innovadoras y colaborar con equipos diversos para alcanzar objetivos.
      </p>
      <p>
        Cuento con una sólida formación en tecnología y un enfoque centrado en el cliente, lo que me permite ofrecer
        productos y servicios de alta calidad.
      </p>
    </section>
  </template>
  
  <style scoped>
  .about-section {
    padding: 50px;
    background-color: #ffffff;
    color: #333;
  }
  .about-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  