<template>
    <section id="inicio" class="home-section">
      <h1>¡Hola, soy Francisco Carle!</h1>
      <p>
        Bienvenido a mi sitio web profesional. Aquí encontrarás información sobre
        mi experiencia, habilidades y proyectos destacados. ¡Explora y conoce más sobre mí!
      </p>
    </section>
  </template>
  
  <style scoped>
  .home-section {
    text-align: center;
    padding: 50px;
    background-color: #f5f5f5;
  }
  </style>
  