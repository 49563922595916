<template>
    <section id="proyectos" class="projects-section">
      <h2>Proyectos Destacados</h2>
      <div class="project">
        <h3>Vistaluz</h3>
        <p>Plataforma para cotización y gestión de recetas ópticas.</p>
      </div>
      <div class="project">
        <h3>OnlyFlans</h3>
        <p>Tienda en línea para flanes gourmet, desarrollada en Django.</p>
      </div>
    </section>
  </template>
  
  <style scoped>
  .projects-section {
    padding: 50px;
    background-color: #ffffff;
  }
  .projects-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .project {
    margin: 20px 0;
  }
  </style>
  